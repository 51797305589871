import React, { memo, useContext, useMemo, Suspense, startTransition, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { DeviceDetector } from 'utils/dd';
import {
  LazyProductCarousel,
  LazyCategoriesCarousel,
  LazyCategoriesGrid,
  LazyFullBanner,
  LazySingleBanner,
  LazyRichText,
  LazySlider,
  LazyProductGrid,
} from './LazyComponents';
interface ICmsBlocksProps {
  cmsData: any;
  center?: boolean;
  isMobileFlagFromServerSide?: boolean;
  isPLP?: boolean;
  isBot?: boolean;
}

const CmsBlocks = (props: ICmsBlocksProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { cmsData, center = true, isMobileFlagFromServerSide, isPLP = false } = props;
  const pickedImgUrl = useContext(DeviceDetector).isMobile ? 'mobileUrl' : 'url';
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setMounted(true);
    });
  }, []);
  const renderCmsBlocks = useMemo(() => {
    if (!mounted) return null;

    return (
      cmsData?.data &&
      cmsData?.data.map((cmsItem: any, key: number) => {
        switch (cmsItem.type) {
          case 'slider':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />}>
                <LazySlider isPLP={isPLP} cmsItem={cmsItem} />
              </Suspense>
            );
          case 'categories_carousel':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[200px] bg-gray-100 rounded-lg' />}>
                <LazyCategoriesCarousel isPLP={isPLP} cmsItem={cmsItem} push={push} t={t} />
              </Suspense>
            );
          case 'categories_grid':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />}>
                <LazyCategoriesGrid cmsItem={cmsItem} push={push} t={t} />
              </Suspense>
            );
          case 'product_carousel':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[300px] bg-gray-100 rounded-lg' />}>
                <LazyProductCarousel cmsItem={cmsItem} center={center} push={push} t={t} />
              </Suspense>
            );
          case 'product_grid':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />}>
                <LazyProductGrid cmsItem={cmsItem} item_list_name={cmsItem.title} />
              </Suspense>
            );
          case 'single_banner':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[350px] bg-gray-100 rounded-lg' />}>
                <LazySingleBanner cmsItem={cmsItem} pickedImgUrl={pickedImgUrl} />
              </Suspense>
            );
          case 'full_banner':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[390px] bg-gray-100 rounded-lg' />}>
                <LazyFullBanner cmsItem={cmsItem} pickedImgUrl={pickedImgUrl} />
              </Suspense>
            );
          case 'rich_text':
            return (
              <Suspense key={key} fallback={<div className='animate-pulse h-[200px] bg-gray-100 rounded-lg' />}>
                <LazyRichText cmsItem={cmsItem} />
              </Suspense>
            );
        }
      })
    );
  }, [cmsData, mounted]);

  return <>{renderCmsBlocks}</>;
};

export default memo(CmsBlocks);
